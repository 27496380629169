import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_RUNNING_WORDS= gql`

    query getRunningWords{
        getRunningWords
    }`;

function GetRunningWords() {
    //executing the query
    const {loading, data, error} = useQuery(GET_RUNNING_WORDS)

    if (loading) {
        return "LOADING";
    }

    if (error != null) {
        if (error.networkError)
        {
            throw(error.networkError);
        }
        else
        {
            error.graphQLErrors.forEach(err => {
                throw(err.message);
            });
        }
    }else {
        return data.getRunningWords;
    }
}

export default GetRunningWords;
