import './polyfills';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ApolloClientCreator from "./ApolloClientCreator";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <BrowserRouter>
        <ApolloClientCreator />
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.register();
