import React, {useState, useCallback, useEffect} from "react";
import './App.css';
import neural_solutions_logo from './neural_solutions.png';
import logo from './icon.png'
import sit_and_order_logo from './icon.png'
import top from './top.png'
import bottom from './bottom.png'
import gotoshops from './gotoshops.png'
import forbusinesses from './forbusinesses.png'
import { styled } from "@mui/material/styles";


import TextField from '@mui/material/TextField';
import {Routes, Route, Link, useNavigate} from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Modal from '@mui/material/Modal';
import GetAllRestaurantsFromEatForLess from "./graphql/getAllRestaurantsFromEatForLess";

import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@material-ui/icons/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@material-ui/icons/Email';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import GetAdverts from "./graphql/getAdverts";
import GetAllStaticAdverts from "./graphql/getAllStaticAdverts";
import GetRunningWords from "./graphql/getRunningWords";
import {ADD_ORDER} from "./graphql/addOrder";
import {Button, InputAdornment, makeStyles} from "@material-ui/core";
import {Mutation} from "react-apollo"
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {CONTACT_US} from "./graphql/contactUs";
import gql from "graphql-tag";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PlaceIcon from '@mui/icons-material/Place';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ScheduleIcon from '@mui/icons-material/Schedule';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import { useReactPWAInstall } from "react-pwa-install";
import ScrollText from "react-scroll-text";
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import PWAPrompt from 'react-ios-pwa-prompt'
import myLogo from "./icon.png";

import ReactGA from 'react-ga';
import {Search} from "@material-ui/icons";

ReactGA.initialize("UA-237446633-1");

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    const runningWords = GetRunningWords()

    const restaurants = GetAllRestaurantsFromEatForLess()

    console.log(restaurants)

    const [search, setSearch] = React.useState("");
    let localities = {}
    let localitiesArray = []

    let deliveryStatuses = {}

    if (restaurants !== "LOADING") {
        localities = restaurants.reduce((groups, item) => {
            const group = (groups[item.locality] || []);
            group.push(item);
            groups[item.locality] = group;
            return groups;
        }, {});

        localitiesArray = Object.entries(localities).sort(
            (a, b) => {
                if ((b[0]).toLocaleLowerCase() > (a[0]).toLocaleLowerCase()) return -1;
                else return 1;
            }
        )

        deliveryStatuses = restaurants.reduce((groups, item) => {
            const group = (groups[item.offersDelivery] || []);
            group.push(item);
            groups[item.offersDelivery] = group;
            return groups;
        }, {});
    }

    if (restaurants === "LOADING") {
        return (
            <div style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "#f5683f",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img style={{width: "100%", maxWidth: "250px", marginBottom: "10px"}} src={sit_and_order_logo}/>
                <Loader
                    type="ThreeDots"
                    color="white"
                    timeout={100000}
                />
            </div>
        )
    } else {

        return (
            <div className="App">
                <PWAPrompt copyBody="This website has app functionality. Add it to your home screen to download it."
                           permanentlyHideOnDismiss={false} timesToShow={1000}/>
                <Routes>
                    <Route path="/" element={<Home data={localitiesArray} restaurants={restaurants} search={search} setSearch={setSearch}/>}/>
                    <Route path="/shops" element={<Shops data={localitiesArray} restaurants={restaurants}  search={search} setSearch={setSearch}/>}/>
                    <Route path="/delivery" element={<Delivery data={deliveryStatuses[true]}/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    {/*<Route path="/search" element={<Search restaurants={restaurants}/>}/>*/}
                    {restaurants.map(restaurant => (
                        <Route path={restaurant.path}
                               element={<Restaurant data={restaurant} runningWords={runningWords}/>}/>
                    ))}
                    {localitiesArray.map(locality => (
                        <>
                            <Route path={locality[0].toLowerCase().replace(/\W/g, '')}
                                   element={<Locality data={locality}/>}/>
                        </>
                    ))}

                    <Route path="*" element={<PageNotFound/>}/>
                </Routes>
            </div>
        );
    }
}

function MainHeader() {
    return (
        <div className="header">
            <div className="header_flex">
                <div style={{display: "flex", alignItems: "center"}}>
                    <img src={logo} style={{maxWidth: "50px", marginRight: "10px"}}/>
                    <Link to="/">
                        <h1>eatforless.mt</h1>
                    </Link>
                </div>
                <div className="icons_right">
                    <Link to="/contact" className="icon_link" style={{paddingRight: "10px"}}>
                        <PhoneIcon sx={{fontSize: "40px"}} style={{
                            backgroundColor: "#007c34",
                            borderRadius: "25px",
                            padding: "10px",
                            height: "50px",
                            width: "50px"
                        }}/>
                    </Link>
                    {/*<Link to="/search" className="icon_link">*/}
                    {/*    <SearchIcon sx={{fontSize: "40px"}} style={{*/}
                    {/*        backgroundColor: "#007c34",*/}
                    {/*        borderRadius: "25px",*/}
                    {/*        padding: "10px",*/}
                    {/*        height: "50px",*/}
                    {/*        width: "50px"*/}
                    {/*    }}/>*/}
                    {/*</Link>*/}
                </div>
            </div>
        </div>
    )
}

function SecondHeader(props) {

    const staticAdverts = GetAllStaticAdverts()

    if (staticAdverts === "LOADING") {
        return (<></>);
    }

    console.log(staticAdverts)

    return (
        <>
            <div style={{
                display: "flex",
                flexWrap: "nowrap",
                width: "100%",
                paddingLeft: "2px",
                paddingRight: "2px",
                columnGap: "5px",
                backgroundColor: "rgb(245, 104, 63)",
                paddingBottom: "10px"
            }}>
                <a href={staticAdverts[0].url} style={{width: "50%"}} target="_blank">
                    <Button style={{
                        width: "100%",
                        backgroundColor: "rgb(245, 104, 63)",
                        borderRadius: "10px 10px 10px 10px",
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.5)"
                    }}>
                        <h3 style={{fontSize: "15px", color: "#000000", textAlign: "left"}}>{staticAdverts[0].text}</h3>
                    </Button>
                </a>
                <a href={staticAdverts[1].url} style={{width: "50%"}} target="_blank">
                    <Button style={{
                        width: "100%",
                        backgroundColor: "rgb(245, 104, 63)",
                        borderRadius: "10px 10px 10px 10px",
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.5)"
                    }}>
                        <h3 style={{fontSize: "15px", color: "#000000", textAlign: "left"}}>{staticAdverts[1].text}</h3>
                    </Button>
                </a>
            </div>
            <div style={{display: "flex", flexWrap: "nowrap", width: "100%", marginBottom: "10px"}}>
                {props.locality ?
                    <>
                        <Link to="/shops" style={{width: "50%"}}>
                            <Button style={{
                                width: "100%",
                                backgroundColor: "rgb(245, 104, 63)",
                                borderRadius: "0px 0px 10px 10px",
                                boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.5)"
                            }}>
                                <PlaceIcon/>
                                <h3 style={{
                                    fontSize: "15px",
                                    color: "#000000",
                                    marginLeft: "5px",
                                    textAlign: "left"
                                }}>By Locality</h3>
                            </Button>
                        </Link>
                        <Link to="/delivery" style={{width: "50%"}}>
                            <Button style={{
                                width: "100%",
                                backgroundColor: "rgba(245, 104, 63, 0.3)",
                                borderRadius: "0px 0px 10px 10px"
                            }}>
                                <DeliveryDiningIcon/>
                                <h3 style={{
                                    fontSize: "15px",
                                    color: "#000000",
                                    marginLeft: "5px",
                                    textAlign: "left"
                                }}>Delivery</h3>
                            </Button>
                        </Link>
                    </>
                    :
                    <>
                        <Link to="/shops" style={{width: "50%"}}>
                            <Button style={{
                                width: "100%",
                                backgroundColor: "rgba(245, 104, 63, 0.3)",
                                borderRadius: "0px 0px 10px 10px"
                            }}>
                                <PlaceIcon/>
                                <h3 style={{
                                    fontSize: "15px",
                                    color: "#000000",
                                    marginLeft: "5px",
                                    textAlign: "left"
                                }}>By Locality</h3>
                            </Button>
                        </Link>
                        <Link to="/delivery" style={{width: "50%"}}>
                            <Button style={{
                                width: "100%",
                                backgroundColor: "rgb(245, 104, 63)",
                                borderRadius: "0px 0px 10px 10px",
                                boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.5)"
                            }}>
                                <DeliveryDiningIcon/>
                                <h3 style={{
                                    fontSize: "15px",
                                    color: "#000000",
                                    marginLeft: "5px",
                                    textAlign: "left"
                                }}>Delivery</h3>
                            </Button>
                        </Link>
                    </>
                }
            </div>
        </>
    )
}

function MainFooter() {
    return (
        <>
            <footer>
                <h6 className="copyright_h6"> © 2022 EAT FOR LESS </h6>
                <div className="footer_menu">
                    <h6 style={{paddingRight: "5px", margin: "0px"}}>DEVELOPED BY:</h6>
                    <a className="logo_align" href="https://neuralsolutions.eu/" target="_blank">
                        <img style={{maxHeight: "40px"}} src={neural_solutions_logo}/>
                    </a>
                </div>
            </footer>
        </>
    )
}

function Home(props) {

    console.log(props)
    return (
        <>
            <MainHeader/>
            <SecondHeader locality={true}/>
            <div className="body">

                <SearchInHome restaurants={props.restaurants} search={props.search} setSearch={props.setSearch}/>

                {props.search?.length < 3 &&
                    <>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "20px 0px 20px 0px"
                        }}>
                            <h3 style={{fontSize: "25px", color: "#000000", marginLeft: "5px", textAlign: "left"}}>Choose
                                Locality</h3>
                        </div>

                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                            {props.data.map((locality) => (
                                <Link to={"/" + locality[0].toLowerCase().replace(/\W/g, '')}>
                                    <div
                                        style={{
                                            backgroundColor: "rgba(245, 104, 63, 0.1)",
                                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 3px 8px",
                                            borderRadius: "5px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "300px",
                                            margin: "10px 20px 10px 20px"
                                        }}
                                    >
                                        <h3
                                            style={{
                                                fontSize: "25px",
                                                color: "#000000",
                                                marginLeft: "20px",
                                                textAlign: "left",
                                                padding: "5px 0px 5px 0px"
                                            }}
                                        >
                                            {locality[0]}
                                        </h3>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </>
                }

            </div>
            <MainFooter/>
        </>
    )
}

function Locality(props) {
    return (
        <>
            <MainHeader/>
            <SecondHeader locality={true}/>
            <div className="body">
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: "5px",
                    flexWrap: "wrap",
                    marginTop: "20px"
                }}>
                    <div style={{display: "flex", alignItems: "center", columnGap: "2px"}}>
                        <p style={{
                            fontSize: "25px",
                            textAlign: "left",
                            fontWeight: '700',
                            margin: "0",
                            color: "#f5683f"
                        }}>①</p>
                        <p style={{fontSize: "17px", textAlign: "left", fontWeight: '700', margin: "0"}}>FIND OFFERS</p>
                    </div>
                    <div style={{display: "flex", alignItems: "center", columnGap: "2px"}}>
                        <p style={{
                            fontSize: "25px",
                            textAlign: "left",
                            fontWeight: '700',
                            margin: "0",
                            color: "#f5683f"
                        }}>②</p>
                        <p style={{fontSize: "17px", textAlign: "left", fontWeight: '700', margin: "0"}}>VISIT SHOP</p>
                    </div>
                    <div style={{display: "flex", alignItems: "center", columnGap: "2px"}}>
                        <p style={{
                            fontSize: "25px",
                            textAlign: "left",
                            fontWeight: '700',
                            margin: "0",
                            color: "#f5683f"
                        }}>③</p>
                        <p style={{fontSize: "17px", textAlign: "left", fontWeight: '700', margin: "0"}}>BUY</p>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", margin: "20px 20px 20px 20px"}}>
                    {/*<Link to={'/shops'}>*/}
                    {/*    <div style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>*/}
                    {/*        <ArrowBackIcon/>*/}
                    {/*        <h3 style={{fontSize: "17px", color: "grey", marginLeft: "5px", textAlign: "left"}}>Back to Localities</h3>*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <h3 style={{
                            fontSize: "25px",
                            color: "#000000",
                            textAlign: "left"
                        }}>{props.data[0]}</h3>
                        <div className="divider"/>
                        <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                            {props.data[1].map(restaurant => (
                                <div style={{marginBottom: "40px", width: "400px", margin: "0px 20px 0px 20px"}}>
                                    <Link to={'/' + restaurant.path}>
                                        <div className="restaurant_box">
                                            <div style={{position: "relative", height: "200px", margin: "0px auto"}}>
                                                <img style={{height: "200px"}} src={restaurant.image}/>
                                            </div>
                                            <div className="restaurant_box_bottom">
                                                <h3>{restaurant.name}</h3>
                                                <ArrowCircleRightOutlinedIcon className="icon_arrow" fontSize="large"/>
                                            </div>
                                            {restaurant.hasItems &&
                                                <StarIcon sx={{color: "orange", fontSize: "50px"}}
                                                          style={{position: "absolute", left: "5", top: "5"}}/>
                                            }
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <MainFooter/>
        </>
    )
}

function Delivery(props) {
    return (
        <>
            <MainHeader/>
            <SecondHeader locality={false}/>
            <div className="body">
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0px 0px 0px"
                }}>
                    <h3 style={{fontSize: "25px", color: "#000000", marginLeft: "5px", textAlign: "left"}}>Shops
                        Offering Delivery</h3>
                </div>
                <div style={{display: "flex", flexDirection: "column", margin: "0px 20px 20px 20px"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                            {props.data.map(restaurant => (
                                <div style={{marginBottom: "40px", width: "400px", margin: "0px 20px 0px 20px"}}>
                                    <Link to={'/' + restaurant.path}>
                                        <div className="restaurant_box">
                                            <div style={{position: "relative", height: "200px", margin: "0px auto"}}>
                                                <img style={{height: "200px"}} src={restaurant.image}/>
                                            </div>
                                            <div className="restaurant_box_bottom">
                                                <h3>{restaurant.name}</h3>
                                                <ArrowCircleRightOutlinedIcon className="icon_arrow" fontSize="large"/>
                                            </div>
                                            {restaurant.hasItems &&
                                                <StarIcon sx={{color: "orange", fontSize: "50px"}}
                                                          style={{position: "absolute", left: "5", top: "5"}}/>
                                            }
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <MainFooter/>
        </>
    )
}


function Shops(props) {
    console.log(props.restaurants)
    return (
        <>
            <MainHeader/>
            <SecondHeader locality={true}/>
            <div className="body">
                <SearchInHome restaurants={props.restaurants} search={props.search} setSearch={props.setSearch}/>
                {props.search?.length < 3 &&
                    <>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0px 20px 0px"
                }}>
                    <h3 style={{fontSize: "25px", color: "#000000", marginLeft: "5px", textAlign: "left"}}>Choose
                        Locality</h3>
                </div>
                 <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                    {props.data.map((locality) => (
                        <Link to={"/" + locality[0].toLowerCase().replace(/\W/g, '')}>
                            <div
                                style={{
                                    backgroundColor: "rgba(245, 104, 63, 0.1)",
                                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 3px 8px",
                                    borderRadius: "5px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "300px",
                                    margin: "10px 20px 10px 20px"
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: "25px",
                                        color: "#000000",
                                        marginLeft: "20px",
                                        textAlign: "left",
                                        padding: "5px 0px 5px 0px"
                                    }}
                                >
                                    {locality[0]}
                                </h3>
                            </div>
                        </Link>
                    ))}
                </div>
                    </>
                }
            </div>
            <MainFooter/>
        </>
    )
}

function Contact() {

    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const handleSubmit = () => {
        setSubmitted(true);
    };
    const [contact] = useMutation(CONTACT_US);

    return (
        <>
            <div className="header">
                <div className="header_flex">
                    <Link to="/">
                        <h1>Eat For Less</h1>
                    </Link>
                    <div className="icons_right">
                        <Link to="/shops" className="icon_link">
                            <Button style={{
                                backgroundColor: "white",
                                padding: "6px 8px",
                                fontFamily: "'Titillium Web', sans-serif"
                            }} variant="contained" startIcon={<StorefrontIcon/>}>
                                <h4 style={{margin: "0px", letterSpacing: "1px"}}>Our Shops</h4>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="body">
                <h3 style={{marginTop: "30px", marginBottom: "10px"}}>Contact Details</h3>
                <div className="contact_info">
                    <div className="contact_info_section">
                        <PhoneIcon className="contact_icon"/>
                        <h4>+356 9901 7126</h4>
                    </div>
                    {/*<div className="contact_info_section">*/}
                    {/*    <EmailIcon className="contact_icon"/>*/}
                    {/*    <h4>info@sitandorder.mt</h4>*/}
                    {/*</div>*/}
                </div>
                <h3 style={{marginTop: "30px", marginBottom: "20px"}}>Contact Eat For Less</h3>
                {submitted ?
                    <h2 className="form_submitted">Form submitted successfully. We will be getting back in touch with
                        you.</h2>
                    :
                    <form
                        onSubmit={e => {

                            e.preventDefault()
                            contact({
                                variables: {
                                    email,
                                    name,
                                    message,
                                }
                            }).then((result) => {
                                handleSubmit()
                            })
                        }}
                    >
                        <div className="form_field_div">
                            <input
                                type="text"
                                placeholder="Your name"
                                name="name"
                                required
                                className="form_field"
                                value={name}
                                onChange={e => {
                                    setName(e.target.value)
                                }}

                            />
                        </div>
                        <div className="form_field_div">
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                required
                                className="form_field"
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </div>
                        <div className="form_field_div">
                        <textarea
                            placeholder="Your message"
                            name="message"
                            required
                            className="form_field"
                            rows="3"
                            value={message}
                            onChange={e => {
                                setMessage(e.target.value)
                            }}
                        />
                        </div>
                        <div className="form_field_div">
                            <button
                                type="submit"
                                className="form_button"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                }
            </div>
            <MainFooter/>
        </>
    )
}

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "& .MuiOutlinedInput-input": {
            color: "white"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "white"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "white"
        },
        "& .MuiInputLabel-outlined": {
            color: "white"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "white"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "white"
        },
        '& ::placeholder': {
            color: "red"
        }
    }
});

function SearchInHome({restaurants, search, setSearch}) {
    const [itemsToSearch, setItemsToSearch] = React.useState([]);

    console.log(restaurants)

    useEffect(() => {
        console.log(restaurants)
        if (restaurants !== "LOADING" && restaurants?.length > 0) {
            let toAdd = []


            for (const restaurant of restaurants) {

                let category_percentage_off = null
                let item_percentage_off = null

                //calculate discounts
                for (let i = 0; i < restaurant.categories.length; i++) {

                    category_percentage_off = restaurant.categories[i].percentageOff

                    for (let j = 0; j < restaurant.categories[i].items.length; j++) {

                        item_percentage_off = restaurant.categories[i].items[j].percentageOff

                        if (item_percentage_off === 0) {
                            if (category_percentage_off === 0) {
                                console.log("MAKING FINAL PRICE")
                                restaurant.categories[i].items[j].finalPrice = restaurant.categories[i].items[j].price
                            } else {
                                restaurant.categories[i].items[j].finalPrice = restaurant.categories[i].items[j].price * ((100 - category_percentage_off) / 100)
                            }
                        } else {
                            restaurant.categories[i].items[j].finalPrice = restaurant.categories[i].items[j].price * ((100 - item_percentage_off) / 100)
                        }

                        restaurant.categories[i].items[j].discounted = restaurant.categories[i].items[j].finalPrice !== restaurant.categories[i].items[j].price;
                        toAdd.push({
                            item: restaurant.categories[i].items[j],
                            name: restaurant.categories[i].items[j].name,
                            restaurant_name: restaurant.name,
                            path: restaurant.path
                        })

                    }
                }

            }
            console.log("setting items to search")
            console.log(toAdd)
            setItemsToSearch(toAdd)
        }
    }, [restaurants])

    console.log(itemsToSearch)
    const dynamicSearch = () => {
        return itemsToSearch.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
    }
    console.log(search)

    const classes = useStyles();

    return (
        <>
            <div className="body body_centre" style={{padding: "10px"}}>

                <TextField
                    style={{maxWidth: "80vw", padding: "10px", minWidth: "40vw"}}
                    className={classes.root}
                    id = "bigFontSizeForSearch"
                    placeholder="Search for items..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{fontSize: "40px"}}>
                                <Search sx={{fontSize: "40px"}}/>
                            </InputAdornment>
                        ),
                        endAdornment: search && (
                            <IconButton
                                aria-label="clear search"
                                onClick={() => setSearch("")}
                            >
                                <CloseIcon sx={{fontSize: "40px", color: "black"}}/>
                            </IconButton>
                        ),
                        style: {
                            border: "2px solid black"
                        }
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    value={search}
                />
                {/*<br />*/}
                {search == null  ||  search?.length < 3 ? <></> :
                    <>
                        {/*<p>{search.length}</p>*/}
                    <DisplaySearchResults results={dynamicSearch()}/>
                    </>

                }

            </div>
        </>
    )
}


function DisplaySearchResults({results}) {

    console.log(results)
    if (results?.length === 0) {
        return (<><p>No Results Found!</p></>)
    }

    return (
        <>

            {/*<div className="body_flex">*/}
            {results.map(item => {
                console.log(item.item)
                console.log(item.item.finalPrice)
                console.log(item.item.finalPrice.toFixed(2))
                return <>
                    <div className="menu_item" onClick={() => {
                        window.location.href = item.path
                    }}>
                        <div className="menu_item_description">
                            {/*<h3 className="h3_restaurant">{item.name}</h3>*/}
                            <h3 className="h4_restaurant">{item.item.short_description}</h3>
                            <div style={{display: "flex", alignItems: "center"}}>
                                {
                                    item.item.discounted &&
                                    <h3 className="h3_restaurant_discounted">€{item.item.price.toFixed(2)}</h3>
                                }
                                <h3 className="h3_restaurant"><span
                                    style={{color: "#28ad19"}}>€{item.item.finalPrice.toFixed(2)}</span></h3>
                                <ArrowCircleRightOutlinedIcon className="icon_arrow_restaurant" fontSize="large"/>
                            </div>
                        </div>
                        <div className="menu_item_picture">
                            <img src={item.item.image}/>
                            {item.item.link_to_order === null || item.item.link_to_order === "" ?
                                <h3 style={{
                                    color: "black",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    marginTop: "5px"
                                }}>SHOW BEFORE BUYING</h3>
                                :
                                <h3 style={{
                                    color: "rgb(245, 104, 63)",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    marginTop: "5px"
                                }}>CLICK TO ORDER ONLINE</h3>
                            }
                            <>
                            </>
                        </div>
                    </div>
                    <div className="divider"></div>
                </>
            })

            }
            {/*</div>*/}
        </>
    );
}

function PageNotFound() {
    return (
        <>
            <MainHeader/>
            <div className="body">
                <h3>404: Page Not Found</h3>
            </div>
            <MainFooter/>
        </>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MenuItemModal(props) {

    console.log(props)

    function onClickClose() {
        props.handleCloseItem();
    }

    return (
        <>
            <Dialog
                fullScreen
                open={props.openItem}
                onClose={onClickClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar style={{backgroundColor: "black"}}>
                        <div style={{flex: 1}}>
                            <h1 className="restaurant_name">{props.currentItem.name}</h1>
                        </div>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClickClose}
                            aria-label="close"
                            style={{padding: "0px"}}
                        >
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{maxWidth: "600px", margin: "0px auto"}}>
                    <div style={{height: "300px", display: "flex", justifyContent: "center"}}>
                        <img src={props.currentItem.image} style={{maxHeight: "300px", width: "auto"}}/>
                    </div>
                    <div className="item_body">
                        <h3 className="h4_restaurant">{props.currentItem.short_description}</h3>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {
                                props.currentItem.discounted &&
                                <h3 className="h3_restaurant_discounted">€{props.currentItem.price.toFixed(2)}</h3>
                            }
                            <h3 className="h3_restaurant">€{props.currentItem.finalPrice.toFixed(2)}</h3>
                        </div>
                        {(props.currentItem.link_to_order !== null && props.currentItem.link_to_order !== "") &&
                            <a href={props.currentItem.link_to_order} target="_blank" rel="noreferrer">
                                <Button style={{
                                    marginTop: "20px",
                                    color: "black",
                                    backgroundColor: "rgb(245, 104, 63)",
                                    borderRadius: "3px"
                                }}><h3 style={{color: "black", fontSize: "15px"}}>Order Online</h3></Button>
                            </a>
                        }
                    </div>
                </div>
            </Dialog>
        </>
    )
}

function Arrow_Left({children, disabled, onClick}) {
    return (
        <ArrowBackIosIcon
            disabled={disabled}
            onClick={onClick}
            style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                right: "1%",
                opacity: disabled ? "0" : "1",
                userSelect: "none",
                marginTop: "18px",
                color: "#f5683f",
                fontSize: "30px"
            }}
        >
            {children}
        </ArrowBackIosIcon>
    );
}

function Arrow_Right({children, disabled, onClick}) {
    return (
        <ArrowForwardIosIcon
            disabled={disabled}
            onClick={onClick}
            style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                right: "1%",
                opacity: disabled ? "0" : "1",
                userSelect: "none",
                marginTop: "18px",
                color: "#f5683f",
                fontSize: "30px"
            }}
        >
            {children}
        </ArrowForwardIosIcon>
    );
}

function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} =
        React.useContext(VisibilityContext);

    return (
        <Arrow_Left disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            Left
        </Arrow_Left>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);

    return (
        <Arrow_Right disabled={isLastItemVisible} onClick={() => scrollNext()}>
            Right
        </Arrow_Right>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function Restaurant(props) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let restaurant_data = props.data
    let category_percentage_off = null
    let item_percentage_off = null

    for (let i = 0; i < restaurant_data.categories.length; i++) {

        category_percentage_off = restaurant_data.categories[i].percentageOff

        for (let j = 0; j < restaurant_data.categories[i].items.length; j++) {

            item_percentage_off = restaurant_data.categories[i].items[j].percentageOff

            if (item_percentage_off === 0) {
                if (category_percentage_off === 0) {
                    restaurant_data.categories[i].items[j].finalPrice = restaurant_data.categories[i].items[j].price
                } else {
                    restaurant_data.categories[i].items[j].finalPrice = restaurant_data.categories[i].items[j].price * ((100 - category_percentage_off) / 100)
                }
            } else {
                restaurant_data.categories[i].items[j].finalPrice = restaurant_data.categories[i].items[j].price * ((100 - item_percentage_off) / 100)
            }

            if (restaurant_data.categories[i].items[j].finalPrice === restaurant_data.categories[i].items[j].price) {
                restaurant_data.categories[i].items[j].discounted = false
            } else {
                restaurant_data.categories[i].items[j].discounted = true
            }
        }
    }

    const [current_category, setCurrentCategory] = React.useState(restaurant_data.categories[0]);
    const [current_category_name, setCurrentCategoryName] = React.useState(restaurant_data.categories[0] == null ? "" : restaurant_data.categories[0].category_name);

    const handleCategoryChange = (category) => {
        setCurrentCategory(category)
        setCurrentCategoryName(category.category_name)
    }

    const [current_category_order, setCurrentCategoryOrder] = React.useState(0);

    const [currentItem, setCurrentItem] = React.useState(restaurant_data.categories.length === 0 ? [] : restaurant_data.categories[0].items[0]);
    const [openItem, setOpenItem] = React.useState(false);

    const handleOpenItem = (item) => {
        setCurrentItem(item);
        setOpenItem(true);
    };
    const handleCloseItem = () => {
        setOpenItem(false);
    };

    const phoneNumber = "tel:" + restaurant_data.phone_number

    const data3 = GetAdverts()

    let navigate = useNavigate();

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                        <CloseIcon onClick={handleClose} style={{textAlign: "right"}}/>
                    </div>
                    <h3 className="h4_restaurant" style={{textAlign: "justify"}}>{restaurant_data.closing_time}</h3>
                </Box>
            </Modal>
            {restaurant_data.promo_code !== null && restaurant_data.promo_code.length > 0 &&
                <div style={{position: "fixed", bottom: "65px", width: "100%", zIndex: "999999"}}>
                    <h3 style={{
                        color: "black",
                        backgroundColor: "rgba(245, 104, 63, 1)",
                        borderRadius: "20px 20px 0px 0px",
                        margin: "0px 20px 0px 20px",
                        padding: "3px 0px 3px 0px",
                    }}>{restaurant_data.promo_code}</h3>
                </div>
            }
            {data3 &&
                <div className="ad" style={{display: "flex", justifyContent: "center"}}>
                    <a href={data3[0].url} style={{display: "inline-block"}}>
                        <img src={data3[0].pic_path}
                             style={{width: "100%", maxHeight: "50px", maxWidth: "430px", display: "block"}}/>
                    </a>
                </div>
            }
            <div style={{position: "fixed", bottom: "0", width: "100%", zIndex: "9999"}}>
                <div className="header_restaurant_modified">
                    <div className="header_flex_modified">
                        <div style={{display: "flex"}} onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="large"/>
                            <h2 style={{
                                color: "black",
                                marginLeft: "3px",
                                marginTop: "0px",
                                marginBottom: "0px"
                            }}>Shops</h2>
                        </div>
                        <div style={{display: "flex", columnGap: "10px"}}>
                            {restaurant_data.closing_time !== null &&
                                <div onClick={handleOpen} className="icons_right">
                                    <ScheduleIcon fontSize="large"/>
                                </div>
                            }
                            <a href={phoneNumber} className="icons_right">
                                <PhoneIcon fontSize="large"/>
                            </a>
                            <a href={restaurant_data.map} className="icons_right" target="_blank">
                                <LocationOnIcon fontSize="large"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header_restaurant">
                <div className="header_flex">
                    <div style={{display: "flex"}}>
                        <h1 className="restaurant_name">{restaurant_data.name}</h1>
                    </div>
                </div>
            </div>
            <div style={{
                backgroundColor: "white",
                color: "#28ad19",
                fontSize: "20px",
                padding: "7px 0px 7px 0px",
                fontFamily: "Titillium Web, sans-serif",
                width: "700px",
                margin: "0 auto"
            }}>
                <ScrollText speed={100}>
                    {props.runningWords}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {props.runningWords}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </ScrollText>
            </div>
            {
                restaurant_data.categories.length === 0

                    ?

                    <h2 style={{color: "black"}}>There are no items currently for sale.</h2>

                    :
                    <>
                        <div className="body_restaurant">
                            <div className="category_scroller">
                                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                    {restaurant_data.categories.map(category => (
                                        <div style={{minWidth: "90px", padding: "0px 5px 0px 5px"}} onClick={
                                            () => handleCategoryChange(category)
                                        }>
                                            <img className="icon_category" src={category.image}/>

                                            {
                                                category.category_name === "Special Offer"
                                                    ?
                                                    <h4 style={{
                                                        margin: "0px",
                                                        marginBottom: "10px",
                                                        color: "orange"
                                                    }}>{category.category_name}</h4>
                                                    :
                                                    current_category_name === category.category_name
                                                        ?
                                                        <h4 style={{
                                                            margin: "0px",
                                                            marginBottom: "8px",
                                                            borderBottom: "2px solid black",
                                                            display: "inline-block",
                                                            minWidth: "60px"
                                                        }}>{category.category_name}</h4>
                                                        :
                                                        <h4 style={{
                                                            margin: "0px",
                                                            marginBottom: "10px",
                                                            color: "#5a5a5a"
                                                        }}>{category.category_name}</h4>
                                            }
                                        </div>
                                    ))}
                                </ScrollMenu>
                            </div>
                            <div className="body_flex">
                                {current_category.items.map(item => (
                                    <>
                                        <div className="menu_item" onClick={() => handleOpenItem(item)}>
                                            <div className="menu_item_description">
                                                {/*<h3 className="h3_restaurant">{item.name}</h3>*/}
                                                <h3 className="h4_restaurant">{item.short_description}</h3>
                                                <div style={{display: "flex", alignItems: "center"}}>
                                                    {
                                                        item.discounted &&
                                                        <h3 className="h3_restaurant_discounted">€{item.price.toFixed(2)}</h3>
                                                    }
                                                    <h3 className="h3_restaurant"><span
                                                        style={{color: "#28ad19"}}>€{item.finalPrice.toFixed(2)}</span>
                                                    </h3>
                                                    <ArrowCircleRightOutlinedIcon className="icon_arrow_restaurant"
                                                                                  fontSize="large"/>
                                                </div>
                                            </div>
                                            <div className="menu_item_picture">
                                                <img src={item.image}/>
                                                {item.link_to_order === null || item.link_to_order === "" ?
                                                    <h3 style={{
                                                        color: "black",
                                                        fontSize: "13px",
                                                        textAlign: "center",
                                                        marginTop: "5px"
                                                    }}>SHOW BEFORE BUYING</h3>
                                                    :
                                                    <h3 style={{
                                                        color: "rgb(245, 104, 63)",
                                                        fontSize: "13px",
                                                        textAlign: "center",
                                                        marginTop: "5px"
                                                    }}>CLICK TO ORDER ONLINE</h3>
                                                }
                                                <>
                                                </>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                    </>
                                ))}
                            </div>
                        </div>
                        <MenuItemModal currentItem={currentItem} openItem={openItem} setOpenItem={setOpenItem}
                                       handleOpenItem={handleOpenItem} handleCloseItem={handleCloseItem}/>
                    </>
            }
        </>
    )
}


export default App;
